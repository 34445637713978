
  import React from "react"
  import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'

  import projectShape from "../images/project-shape.jpg"
import projectShapeP500 from "../images/project-shape-p-500.jpeg"
import projectShapeP800 from "../images/project-shape-p-800.jpeg"
import projectShapeP1080 from "../images/project-shape-p-1080.jpeg"
import projectShapeP1600 from "../images/project-shape-p-1600.jpeg"
import shape2 from "../images/shape-2.png"
import shape3 from "../images/shape-3.png"
import shape3P500 from "../images/shape-3-p-500.png"
import shape3P800 from "../images/shape-3-p-800.png"
import shape3P1080 from "../images/shape-3-p-1080.png"
import icons8Hulk250 from "../images/icons8-hulk-250.png"
import icons8Clock500 from "../images/icons8-clock-500.png"
import icons8ThumbsUp500 from "../images/icons8-thumbs-up-500.png"
import icons8CoffeeBeans500 from "../images/icons8-coffee-beans-500.png"
import shape9 from "../images/shape-9.png"
import shape10 from "../images/shape-10.png"
import icons8Flower250 from "../images/icons8-flower-250.png"
import icons8Asteroid250 from "../images/icons8-asteroid-250.png"
import icons8Index250 from "../images/icons8-index-250.png"
import icons8OnlineShopping250 from "../images/icons8-online-shopping-250.png"
import icons8TechnicalSupport250 from "../images/icons8-technical-support-250.png"
import icons8Template250 from "../images/icons8-template-250.png"
import icons8Infinite250 from "../images/icons8-infinite-250.png"
import shape8 from "../images/shape-8.png"
import shape8P500 from "../images/shape-8-p-500.png"
import arrowTop from "../images/arrow-top.png"

  const IndexView = (props) => {
    const { t } = useTranslation()

    return (<div>
  <div id="Top" className="hero"><img src={projectShape} loading="lazy" width={650} sizes="(max-width: 991px) 100vw, 650px" srcSet={`${projectShapeP500} 500w, ${projectShapeP800} 800w, ${projectShapeP1080} 1080w, ${projectShapeP1600} 1600w, ${projectShape} 2000w`} alt="projectShape" className="image-hero" />
    <div className="container">
      <div className="hero-grid">
        <div id="w-node-d20b459b-6f6e-ef4b-7a3c-385091f5c00f-7cba63a1" className="div-block-3">
          <h1 className="display-1"><Trans>Nous créons gratuitement des sites pour vignerons 🍷</Trans></h1>
          <Link to="/concept" className="submit-button w-button"><Trans>Découvrir notre concept&nbsp;&nbsp;&nbsp;➡️</Trans></Link>
        </div>
      </div>
    </div><img src={shape2} loading="lazy" width={60} alt="shape2" className="scribble-1" /><img src={shape3} loading="lazy" width={80} sizes="(max-width: 479px) 100vw, 80px" srcSet={`${shape3P500} 500w, ${shape3P800} 800w, ${shape3P1080} 1080w, ${shape3} 1200w`} alt="shape3" className="scribble-2" />
  </div>
  <div className="section _50px-top wf-section">
    <div className="container">
      <div className="display-wrapper">
        <div className="badge yellow"><Trans>Nos 3 piliers</Trans></div>
        <h2 className="display-2" />
      </div>
      <div className="top-margin-70px">
        <div className="features-grid">
          <div className="features-content">
            <div className="icon-pilier">⭐️</div>
            <h3 className="display-3"><Trans>Qualité</Trans></h3>
            <p className="pilier-paragraph"><Trans>Nous nous efforçons toujours à réaliser des sites beaux, rapides et uniques.</Trans><br /><br /><Trans>Nos sites doivent obtenir un minimum de 90/100&nbsp;dans chaque catégorie de Google Lighthouse pour être prêt.</Trans><br /></p>
            <div className="div-block-10">
              <Link to="/concept" className="remove-button center home-link"><Trans>En savoir plus</Trans></Link>
            </div>
          </div>
          <div className="features-content top-margin-20px">
            <div className="icon-pilier">🎁</div>
            <h3 className="display-3"><Trans>Gratuité</Trans></h3>
            <p className="pilier-paragraph"><Trans>Nous nous rémunérons exclusivement à la commission.&nbsp;</Trans><br /><br /><Trans>Vous n'avez donc rien à dépenser pour commencer à vendre vos vins.</Trans><br /><Trans>Ni maintenant ni demain.</Trans><br /></p>
            <div className="div-block-10">
              <Link to="/concept" className="remove-button center home-link"><Trans>En SAVOIR PLUS</Trans></Link>
            </div>
          </div>
          <div className="features-content top-margin-20px">
            <div className="icon-pilier">🍹</div>
            <h3 className="display-3"><Trans>Simplicité</Trans></h3>
            <p className="pilier-paragraph"><Trans>De la création, à la vente en passant par l'expédition nous avons réfléchi à une solution complète.</Trans><br /><br /><Trans>Tout devrait être aussi simple qu'une offre unique et complète.</Trans><br /></p>
            <div className="div-block-10">
              <Link to="/concept" className="remove-button center home-link"><Trans>EN SAVOIR PLUS</Trans></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="section color wf-section">
    <div className="container _80">
      <div className="align-center">
        <div className="badge yellow"><Trans>Fun Facts</Trans></div>
      </div>
      <div className="fun-wrapper">
        <div className="fun-facts-wrapper to-hide"><img src={icons8Hulk250} width={60} alt="icons8Hulk250" className="fun-image" />
          <div>
            <h3 className="fun-number">1</h3>
            <p className="paragraph-biger"><Trans>Équipe passionnée</Trans></p>
          </div>
        </div>
        <div className="fun-facts-wrapper _2"><img src={icons8Clock500} width={60} alt="icons8Clock500" className="fun-image" />
          <div>
            <h3 className="fun-number">9.000</h3>
            <p className="paragraph-biger"><Trans>Heures de sommeil à récupérer</Trans></p>
          </div>
        </div>
        <div className="fun-facts-wrapper _3"><img src={icons8ThumbsUp500} width={60} alt="icons8ThumbsUp500" className="fun-image" />
          <div>
            <h3 className="fun-number">2</h3>
            <p className="paragraph-biger"><Trans>Vignerons nous ont fait confiance</Trans></p>
          </div>
        </div>
        <div className="fun-facts-wrapper _4"><img src={icons8CoffeeBeans500} width={60} alt="icons8CoffeeBeans500" className="fun-image" />
          <div>
            <h3 className="fun-number">100+</h3>
            <p className="paragraph-biger"><Trans>Tasses de café</Trans></p>
          </div>
        </div>
      </div>
      <div className="top-margin-40px">
        <div className="align-center">
          <Link to="/contact" className="primary-button w-button"><Trans>Nous contacter</Trans></Link>
        </div>
      </div>
    </div><img src={shape9} loading="lazy" width={100} alt="shape9" className="scribble-5" /><img src={shape10} loading="lazy" width={100} alt="shape10" className="scribble-6" />
  </div>
  <div className="section gray wf-section">
    <div className="container">
      <div className="display-wrapper">
        <div className="badge yellow"><strong><Trans>Features</Trans></strong><br /></div>
        <h3 className="display-2"><Trans>Nos différences</Trans><br /></h3>
        <div className="top-margin-30px">
          <div className="align-center">
            <div>
              <div className="top-margin-50px">
                <div className="featrues-flex">
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8Flower250} loading="lazy" width={30} alt="icons8Flower250" /></div>
                    <p className="paragraph-no-margin"><Trans>Design effervescent</Trans></p>
                  </div>
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8Asteroid250} loading="lazy" width={30} alt="icons8Asteroid250" /></div>
                    <p className="paragraph-no-margin"><Trans>Animations détonantes</Trans></p>
                  </div>
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8Index250} loading="lazy" width={30} alt="icons8Index250" /></div>
                    <p className="paragraph-no-margin"><Trans>CMS </Trans></p>
                  </div>
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8OnlineShopping250} loading="lazy" width={30} alt="icons8OnlineShopping250" /></div>
                    <p className="paragraph-no-margin"><Trans>E-commerce</Trans></p>
                  </div>
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8TechnicalSupport250} loading="lazy" width={30} alt="icons8TechnicalSupport250" /></div>
                    <p className="paragraph-no-margin"><Trans>Support souriant</Trans></p>
                  </div>
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8Template250} loading="lazy" width={30} alt="icons8Template250" /></div>
                    <p className="paragraph-no-margin"><Trans>SEO</Trans></p>
                  </div>
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8Infinite250} loading="lazy" width={30} alt="icons8Infinite250" /></div>
                    <p className="paragraph-no-margin"><Trans>Possibilités infinies</Trans></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="wf-section">
    <div className="container">
      <div className="cta-wrapper">
        <div className="badge white"><Trans>Contact</Trans></div>
        <h4 className="display-2"><Trans>Vous avez un projet ?&nbsp;</Trans><br /><Trans>Parlons-en !</Trans></h4>
        <div className="top-margin-50px">
          <div className="meta-flex center">
            <Link to="/contact" className="primary-button green w-button"><Trans>Nous contacter</Trans></Link>
          </div>
        </div><img src={shape8} loading="lazy" width={120} sizes="(max-width: 479px) 70px, 120px" srcSet={`${shape8P500} 500w, ${shape8} 546w`} alt="shape8" className="scribble-4" /><img src={shape3} loading="lazy" width={60} sizes="(max-width: 479px) 100vw, 60px" srcSet={`${shape3P500} 500w, ${shape3P800} 800w, ${shape3P1080} 1080w, ${shape3} 1200w`} alt="shape3" className="scribble-2 left" />
      </div>
    </div>
  </div>
  <a href="#Top" style={{WebkitTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}} className="go-top w-inline-block"><img src={arrowTop} loading="lazy" width={256} alt="arrowTop" className="image" /></a>
</div>)
  };
  
  export default IndexView
  