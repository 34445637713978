import React from 'react'
import { PageProps } from 'gatsby'

import Layout from '../components/layout'
import IndexView from '../views/index.view.js'
import { useState } from 'react'

const IndexPage: React.FC<PageProps> = () => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const hiddenTags = (
    <React.Fragment>
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="Audit-Form" />
    </React.Fragment>
  )

  const submitAuditForm = async (e: any) => {
    e.preventDefault()
    const formData: any = new FormData(e.target)

    await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setSuccess(true)
        setError(false)
      })
      .catch(() => {
        setError(true)
        setSuccess(false)
      })
  }

  return (
    <Layout seo={{ webflow: '5fea4622987096757cba63a1' }}>
      <IndexView submitAuditForm={submitAuditForm} isSuccess={success} isError={error} hiddenTags={hiddenTags} />
    </Layout>
  )
}

export default IndexPage
